import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

import "react-day-picker/lib/style.css";

export default props => {
  const [fechas, setFechas] = useState(
    props.fechas ? props.fechas.map(f => new Date(f)) : []
  );

  const handleDayClick = (day, { selected }) => {
    const selectedDays = [...fechas];
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    setFechas(selectedDays);
  };

  return (
    <div>
      <DayPicker selectedDays={fechas} onDayClick={handleDayClick} />
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => props.update(fechas)} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </div>
  );
};
