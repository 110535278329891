import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { IconButton } from "@material-ui/core";

import Calendario from "./calendario";

export default (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        <CalendarToday />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props.nombre}</DialogTitle>
        <DialogContent>
          <DialogContentText>Seleccionar fechas.</DialogContentText>

          <Calendario
            fechas={props.fechas}
            handleClose={handleClose}
            update={(fechas) => {
              let fech = fechas.map((f) => {
                let fe = new Date(f);
                fe.setHours(8);
                fe.setMinutes(0);
                return fe;
              });

              props.update(fech);
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
