import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPLOAD_FILE } from "../../graphql/publicaciones.mutation";

export const UploadOneFile = ({ id, set, setLoad, cliente }) => {
  const [uploading, setUploading] = useState(false);

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: e => {
      set(e.singleUpload.url);
      setUploading(false);
    }
  });

  if (uploading) {
    return <span>Loading...</span>;
  }

  return (
    <input
      id={id}
      type="file"
      required
      style={{ display: "none" }}
      accept="image/x-png,image/gif,image/jpeg/png,image/jpg"
      onChange={({
        target: {
          validity,
          files: [file]
        }
      }) => {
        setUploading(true);
        setLoad(true);
        return validity.valid && uploadFile({ variables: { file, cliente } });
      }}
    />
  );
};
