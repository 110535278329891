import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Grid } from "./styles";
import { Article } from "./article";
import { useQuery } from "@apollo/react-hooks";
import {
  PUBLICACIONES_VERMAS_QUERY,
  PUBLICACIONES_GRATIS,
} from "../../graphql/publicaciones.query";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    padding: theme.spacing(3, 2),
  },
}));

const POSTS_PER_PAGE = 100;

const Relacionadas = ({ value, localidad }) => {
  const { data, loading } = useQuery(PUBLICACIONES_VERMAS_QUERY, {
    variables: {
      limit: POSTS_PER_PAGE,
      offset: 0,
      localidad: localidad,
      queryString: value,
      todos: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const gratis = useQuery(PUBLICACIONES_GRATIS, {
    variables: {
      limit: POSTS_PER_PAGE,
      offset: 0,
      localidad: localidad,
      queryString: value,
      todos: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const classes = useStyles();

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Publicaciones encontradas por etiquetas
        </Typography>

        {loading && <span>loading...</span>}
        {!loading && data != null && data.verMas && (
          <Grid>
            {data.verMas.map((p) => (
              <Article p={p} key={p.idItem} />
            ))}
          </Grid>
        )}
      </Paper>
      <Paper className={classes.root}>
        <Typography variant="h5" component="h3">
          Publicaciones Gratis
        </Typography>

        {gratis.loading && <span>loading...</span>}
        {!gratis.loading && gratis.data != null && gratis.data.gratis && (
          <Grid>
            {gratis.data.gratis
              .filter((p) => !p.borrado)
              .map((p) => (
                <Article p={p} key={p.idItem} />
              ))}
          </Grid>
        )}
      </Paper>
    </Fragment>
  );
};

export const ListaPublicaciones = ({
  principales = [],
  loading = false,
  value = "",
  localidad = "concordia",
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Paper className={classes.root}>
        {loading && <span>Loading...</span>}

        {!principales.length && (
          <Typography variant="h5" component="h3">
            No se encontraron resultados
          </Typography>
        )}
        {!loading && principales.length > 0 && (
          <Fragment>
            <Typography variant="h5" component="h3">
              Publicaciones encontradas por rubros
            </Typography>

            <Grid>
              {principales
                .filter((p) => !p.borrado)
                .map((p) => (
                  <Article p={p} key={p.idItem} />
                ))}
            </Grid>
          </Fragment>
        )}
      </Paper>

      {value.length > 3 && !loading && (
        <Relacionadas value={value} localidad={localidad} />
      )}
    </Fragment>
  );
};

export const TopVisitadosList = ({ principales = [], loading = false }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Paper className={classes.root}>
        {loading && <span>Loading...</span>}

        {!principales.length && (
          <Typography variant="h5" component="h3">
            No se encontraron resultados
          </Typography>
        )}
        {!loading && principales.length > 0 && (
          <Fragment>
            <Typography variant="h5" component="h3">
              TOP VISITADOS
            </Typography>

            <Grid>
              {principales
                .filter((p) => !p.borrado)
                .map((p) => (
                  <Article p={p} key={p.idItem} />
                ))}
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
};

export const UltimosModificadosList = ({
  principales = [],
  loading = false,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Paper className={classes.root}>
        {loading && <span>Loading...</span>}

        {!principales.length && (
          <Typography variant="h5" component="h3">
            No se encontraron resultados
          </Typography>
        )}
        {!loading && principales.length > 0 && (
          <Fragment>
            <Typography variant="h5" component="h3">
              ULTIMOS MODIFICADOS
            </Typography>

            <Grid>
              {principales
                .filter((p) => !p.borrado)
                .map((p) => (
                  <Article p={p} key={p.idItem} />
                ))}
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
};

export const GratisList = ({ principales = [], loading = false }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Paper className={classes.root}>
        {loading && <span>Loading...</span>}

        {!principales.length && (
          <Typography variant="h5" component="h3">
            No se encontraron resultados
          </Typography>
        )}
        {!loading && principales.length > 0 && (
          <Fragment>
            <Typography variant="h5" component="h3">
              PUBLICACIONES GRATIS
            </Typography>

            <Grid>
              {principales
                .filter((p) => !p.borrado)
                .map((p) => (
                  <Article p={p} key={p.idItem} />
                ))}
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
};
