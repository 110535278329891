import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DeleteForever } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export const AlertDelete = ({ id, action }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <IconButton aria-label="delete" onClick={() => setOpen(true)}>
        <DeleteForever color="error" />
      </IconButton>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmar eliminar registro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirma eliminar el registro seleccionado?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              action(id);
              setOpen(false);
            }}
            color="primary"
            autoFocus
            endIcon={<DeleteForever />}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
