import gql from "graphql-tag";

export const CREATE_USUARIO = gql`
  mutation createUsuario($usuario: UsuarioInput!) {
    createUsuario(usuario: $usuario) {
      idItem
      username
      email
      rol
      activo
    }
  }
`;

export const UPDATE_USUARIO = gql`
  mutation updateUsuario($idItem: Int!, $usuario: UsuarioInput!) {
    updateUsuario(idItem: $idItem, usuario: $usuario) {
      idItem
      username
      email
      rol
      activo
    }
  }
`;
export const DELETE_USUARIO = gql`
  mutation deleteUsuario($idItem: Int!) {
    deleteUsuario(idItem: $idItem) {
      deletedCount
    }
  }
`;
