import React from "react";
import { useNearScreen } from "./../../hooks/useNearScreen";
import {
  Card,
  Image,
  Rubro,
  Title,
  Numero,
  Link,
  Disponible,
  Pendiente,
  Suspendido,
} from "./styles";

import codificar from "./../../codificar";

export const Article = ({ p }) => {
  const [show, ref] = useNearScreen();

  return (
    <Link to={`/publicaciones/${p.idItem}`}>
      <Card ref={ref}>
        {show && (
          <div>
            <div>
              <span>Visitas: {p.visitas}</span>
            </div>
            <Rubro>
              {p && p.rubros.length > 0 && <span>{p.rubros[0]}</span>}
            </Rubro>
            <div>
              <Image
                src={p.logo ? codificar(p.logo) : "/img/logo-publicacion.gif"}
              />
            </div>
            <div>
              <Title>{p.title}</Title>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Numero>Cliente: {p.cliente}</Numero>
              </div>
              <div>
                <Numero>Venta: {p.ventainterno}</Numero>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Numero>Edicion: {p.edicion}</Numero>
              </div>
              <div>
                <Numero>Orden: {p.orden}</Numero>
              </div>
            </div>
            {p.esCentimetro && (
              <div>
                <Numero>Es centimetro</Numero>
              </div>
            )}
            <div>
              {!p.suspendido && !p.pendiente && (
                <Disponible>Publicado</Disponible>
              )}
              {p.pendiente && !p.suspendido && <Pendiente>Pendiente</Pendiente>}
              {p.suspendido && <Suspendido>Suspendido</Suspendido>}
            </div>
          </div>
        )}
      </Card>
    </Link>
  );
};
