import gql from "graphql-tag";

export const ETIQUETAS = gql`
  query etiquetas($nombre: String!, $limit: Int!, $offset: Int!) {
    etiquetas(nombre: $nombre, offset: $offset, limit: $limit) {
      nombre
      _id
    }
  }
`;
