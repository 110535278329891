import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircle from "@material-ui/icons/AddCircle";
import { IconButton } from "@material-ui/core";

import List from "@material-ui/core/List";

import Item from "./item";
import Nuevo from "./nuevo";

import { useQuery } from "@apollo/react-hooks";

import { RUBROS } from "./../../graphql/rubros.query";
import { CREATE_RUBRO } from "./../../graphql/rubros.mutation";
import { useMutation } from "@apollo/react-hooks";

const ITEMS_LIMIT = 40;

export default function RubrosDialog({ aceptar, allSelected = [] }) {
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(RUBROS, {
    variables: {
      limit: ITEMS_LIMIT,
      offset: 0,
      nombre: value,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [createRubro] = useMutation(CREATE_RUBRO, {
    onCompleted: (data) => {
      if (data && data.createRubro) {
        setValue(data.createRubro.nombre);
      }
      setLoading(false);
    },
  });

  // const loadMore = () => {
  //   return fetchMore({
  //     variables: {
  //       offset: data.rubros.length,
  //       limit: ITEMS_LIMIT,
  //       nombre: value
  //     },
  //     updateQuery: (previousResult, { rubros }) => {
  //       if (!rubros) {
  //         return previousResult;
  //       }
  //       return Object.assign({}, previousResult, {
  //         rubros: [...previousResult.rubros, ...rubros]
  //       });
  //     }
  //   });
  // };

  // const handleScroll = ({ currentTarget }, onLoadMore) => {
  //   console.log(currentTarget.scrollTop + currentTarget.clientHeight);
  //   console.log(currentTarget.scrollHeight);
  //   if (
  //     currentTarget.scrollTop + currentTarget.clientHeight >=
  //     currentTarget.scrollHeight
  //   ) {
  //     onLoadMore();
  //   }
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        <AddCircle />
      </IconButton>
      <Dialog
        open={open}
        scroll={"paper"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex-root" }}>
            <div>Rubros</div>
            <div>
              <span style={{ fontSize: "16px" }}>Buscar...</span>{" "}
              <input
                style={{
                  height: "30px",
                  padding: "4px",
                  borderRadius: "4px",
                  width: "70%",
                }}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          dividers={true}
          // onScroll={e => handleScroll(e, loadMore)}
        >
          <DialogContentText style={{ height: "300px" }}>
            {loading && <span>Cargando...</span>}
            {!loading &&
              data &&
              data.rubros &&
              data.rubros.map((item) => (
                <Item
                  key={item._id}
                  agregar={() => aceptar(item.nombre)}
                  data={item}
                  enabled={
                    allSelected.findIndex((i) => i === item.nombre) === -1
                  }
                />
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <List>
            <Nuevo
              aceptar={(nombre) => {
                if (nombre.length > 0) {
                  setLoading(true);
                  createRubro({
                    variables: {
                      rubro: { nombre },
                    },
                  });
                }
              }}
            />
          </List>
          {/* <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmar} color="primary">
            Aceptar
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
