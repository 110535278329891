import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import { USUARIOS } from "../../graphql/usuarios.query";

import {
  UPDATE_USUARIO,
  DELETE_USUARIO
} from "../../graphql/usuarios.mutation";

import { UsuarioDialog } from "../UsuarioDialog";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

export const Usuario = ({ usuario }) => {
  const [open, setOpen] = useState(false);

  const [updateUsuario] = useMutation(UPDATE_USUARIO, {
    onCompleted: data => {},

    update(cache, { data: { updateUsuario } }) {
      const { usuarios } = cache.readQuery({ query: USUARIOS });

      let newUsuarios = usuarios.map(b => {
        if (b.idItem === updateUsuario.idItem) {
          return updateUsuario;
        } else {
          return b;
        }
      });

      cache.writeQuery({
        query: USUARIOS,
        data: { usuarios: newUsuarios }
      });
    }
  });

  const [deleteUsuario] = useMutation(DELETE_USUARIO, {
    onCompleted: data => {},

    update(cache, { data: { deleteUsuario } }) {
      const { usuarios } = cache.readQuery({ query: USUARIOS });
      cache.writeQuery({
        query: USUARIOS,
        data: {
          usuarios: usuarios.filter(
            b => b !== undefined && b.idItem !== usuario.idItem
          )
        }
      });
    }
  });

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell padding="checkbox" align={"left"}>
        {usuario.email}
      </TableCell>
      <TableCell padding="checkbox" align={"left"}>
        {usuario.username}
      </TableCell>
      <TableCell padding="checkbox" align={"center"}>
        <UsuarioDialog
          open={open}
          usuario={usuario}
          title={"Editar usuario"}
          cancelar={() => setOpen(false)}
          guardar={ban => {
            let data = { ...ban };
            delete data.idItem;
            delete data.__typename;
            delete data.repassword;
            updateUsuario({
              variables: {
                idItem: usuario.idItem,
                usuario: data
              }
            });
            setOpen(false);
          }}
          eliminar={() => {
            deleteUsuario({
              variables: {
                idItem: usuario.idItem
              }
            });
            setOpen(false);
          }}
        >
          <IconButton onClick={() => setOpen(true)}>
            <Edit />
          </IconButton>
        </UsuarioDialog>
      </TableCell>
    </TableRow>
  );
};
