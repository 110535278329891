import gql from "graphql-tag";

export const CREATE_BANNER = gql`
  mutation createBanner($banner: BannerInput!) {
    createBanner(banner: $banner) {
      idItem
      orientacion
      imagen
      linkeable
      url
    }
  }
`;

export const UPDATE_BANNER = gql`
  mutation updateBanner($idItem: Int!, $banner: BannerInput!) {
    updateBanner(idItem: $idItem, banner: $banner) {
      idItem
      orientacion
      imagen
      linkeable
      url
    }
  }
`;
export const DELETE_BANNER = gql`
  mutation deleteBanner($idItem: Int!) {
    deleteBanner(idItem: $idItem) {
      deletedCount
    }
  }
`;
