import gql from "graphql-tag";

export const USUARIOS = gql`
  query usuarios {
    usuarios {
      idItem
      username
      email
      rol
      activo
    }
  }
`;
