import React, { useContext, Fragment } from "react";
import { Router, Redirect } from "@reach/router";
import { Context } from "./Context";
import { GlobalStyle } from "./styles/GlobalStyles";
import { NotFound } from "./pages/NotFound";
import { Login } from "./pages/Login";

import { PublicacionesPage } from "./pages/Publicaciones";
import { PublicacionPage } from "./pages/Publicacion";
import { NuevoPublicacionPage } from "./pages/NuevoPublicacion";

import { FarmaciasPage } from "./pages/Farmacias";
import { FarmaciaPage } from "./pages/Farmacia";
import { NuevoFarmaciaPage } from "./pages/NuevoFarmacia";

import { BannersPage } from "./pages/Banners";
import { UsuariosPage } from "./pages/Usuarios";

import { Top } from "./pages/Top";
import { Logs } from "./pages/Logs";
import { Gratis } from "./pages/Gratis";

import { Appbar } from "./components/appbar";

export const App = () => {
  const { isAuth, removeAuth } = useContext(Context);

  return (
    <Fragment>
      <GlobalStyle />
      {isAuth && <Appbar removeAuth={removeAuth} />}
      <Router>
        <NotFound default />
        {!isAuth && <Login path="/login" />}
        {!isAuth && <Redirect noThrow from="/" to="/login" />}
        {isAuth && <PublicacionesPage path="/" />}
        {isAuth && <PublicacionesPage path="/publicaciones" />}
        {isAuth && <Gratis path="/gratis" />}
        {isAuth && <Top path="/top" />}
        {isAuth && <Logs path="/loglab" />}
        {isAuth && <PublicacionPage path="/publicaciones/:id" />}
        {isAuth && <NuevoPublicacionPage path="/nuevo/publicacion" />}
        {isAuth && <FarmaciasPage path="/farmacias" />}
        {isAuth && <FarmaciaPage path="/farmacias/:id" />}
        {isAuth && <NuevoFarmaciaPage path="/nuevo/farmacia" />}
        {isAuth && <BannersPage path="/banners" />}
        {isAuth && <UsuariosPage path="/usuarios" />}
        {isAuth && <Redirect noThrow from="/login" to="/" />}
      </Router>
    </Fragment>
  );
};
