import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { PUBLICACION_BY_ID } from "../graphql/publicaciones.query";

import { Publicacion } from "./../components/publicacion";

export const PublicacionPage = ({ id }) => {
  const { data, loading, error } = useQuery(PUBLICACION_BY_ID, {
    variables: {
      idItem: Number(id),
      todo: true,
    },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error || !data.publicacionByID) {
    return <span>Ocurrio un error...</span>;
  }

  return <Publicacion info={data.publicacionByID} />;
};
