import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import { ApolloProvider } from "@apollo/react-hooks";
// import ApolloClient from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { GRAPHQL_URL } from "./constants";

import Context from "./Context";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
  status: {
    danger: "orange",
  },
});

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.idItem || null,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("authorization");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(createUploadLink({ uri: GRAPHQL_URL })), // link: createUploadLink({ uri: GRAPHQL_URL }),
  cache,
});

ReactDOM.render(
  <Context.Provider>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </Context.Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
