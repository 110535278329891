import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LocalidadSelect } from "../localidadSelect";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "30px",
    borderRadius: "50px",
    width: "100%"
  },
  appbar: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "30px",
    borderRadius: "50px",
    width: "auto"
  },
  toolbar: {
    width: "auto",
    borderRadius: "50px"
  }
}));

export const SelectLocalidad = ({ localidad = "concordia", setLocalidad }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <LocalidadSelect localidad={localidad} setLocalidad={setLocalidad} />
        </Toolbar>
      </AppBar>
    </Container>
  );
};
