import gql from "graphql-tag";

export const CREATE_RUBRO = gql`
  mutation createRubro($rubro: RubroInput!) {
    createRubro(rubro: $rubro) {
      _id
      nombre
    }
  }
`;
