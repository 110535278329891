import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const modelo = {
  idItem: 0,
  username: "",
  email: "",
  rol: "",
  activo: true,
  password: "",
  repassword: "",
};

export const UsuarioDialog = ({
  usuario = null,
  open = false,
  children,
  title = "",
  cancelar,
  guardar,
  eliminar,
}) => {
  const [data, setData] = useState(usuario ? usuario : modelo);
  const [error, setError] = useState(null);

  return (
    <div>
      {children}
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth={"md"}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              autoFocus
              margin="dense"
              id="Usuario"
              label="Usuario"
              type="text"
              fullWidth
              defaultValue={data.username}
              onChange={(e) =>
                setData({
                  ...data,
                  username: e.target.value,
                })
              }
            />
            <TextField
              autoFocus
              margin="dense"
              id="Password"
              label="Password"
              type="password"
              fullWidth
              defaultValue={""}
              onChange={(e) =>
                setData({
                  ...data,
                  password: e.target.value,
                })
              }
            />
            <TextField
              autoFocus
              margin="dense"
              id="RePassword"
              label="Repetir password"
              type="password"
              fullWidth
              defaultValue={""}
              onChange={(e) =>
                setData({
                  ...data,
                  repassword: e.target.value,
                })
              }
            />
            <TextField
              autoFocus
              margin="dense"
              id="Email"
              label="Email"
              type="email"
              fullWidth
              defaultValue={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  email: e.target.value,
                })
              }
            />
          </div>

          {error && <span style={{ color: "red" }}>{error}</span>}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              cancelar();
              if (!usuario) {
                setData(modelo);
              } else {
                setData(usuario);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (
                data.username &&
                data.username.length > 3 &&
                data.email &&
                data.email.length > 5 &&
                data.password &&
                data.repassword &&
                data.password.length > 5 &&
                data.password === data.repassword
              ) {
                guardar(data);
                if (!usuario) {
                  setData(modelo);
                }
              } else {
                setError("Todos los campos son obligatorios");
              }
            }}
          >
            Guardar
          </Button>

          {eliminar && (
            <Button
              style={{ color: "red" }}
              onClick={() => {
                eliminar();
              }}
            >
              Eliminar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
