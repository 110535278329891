import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { LOGS } from "../graphql/logs.query";
import { useQuery } from "@apollo/react-hooks";
import { Container } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";

import IconButton from "@material-ui/core/IconButton";

import { Link as LinkRouter } from "@reach/router";

import MaterialTable from "material-table";

const POSTS_PER_PAGE = 1000;

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const Logs = () => {
  const token = localStorage.getItem("authorization");
  const usuario = parseJwt(token);

  const { data } = useQuery(LOGS, {
    variables: {
      limit: POSTS_PER_PAGE,
      offset: 0,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (
    !usuario ||
    (usuario.username !== "admin" && usuario.username !== "Valeria")
  ) {
    return (
      <Fragment>
        <Container>
          <Helmet>
            <title>Guia Local</title>
          </Helmet>

          <h1>La pagina no existe!</h1>
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>Guia Local</title>
        </Helmet>
        {data && data.logs && <SimpleTable logs={data.logs} />}
      </Container>
    </Fragment>
  );
};

const SimpleTable = ({ logs = [] }) => {
  const columns = [
    { title: "Fecha", field: "fecha" },
    { title: "Usuario", field: "usuario" },
    { title: "Email", field: "email" },
    { title: "Publicacion", field: "publicacion" },
  ];

  const data = logs.map((l, i) => {
    return {
      id: i,
      fecha: l.updatedAt,
      usuario: l.usuario.username,
      email: l.usuario.email,
      publicacion: l.publicacion.title,
      url: `/publicaciones/${l.publicacion.idItem}`,
    };
  });

  return (
    <Fragment>
      <br />
      <Paper>
        <MaterialTable
          title="Logs de trabajo"
          columns={columns}
          data={data}
          editable={{
            onRowAdd: (newData) => null,
            onRowUpdate: (newData, oldData) => null,
            onRowDelete: (oldData) => null,
          }}
          actions={[
            {
              icon: (props) => <p>Manage</p>, // <--- This will be overridden by the components prop Actions
              tooltip: "ir",
              onClick: (event, rowData) => console.log("You are editing "),
            },
          ]}
          components={{
            Action: (props) => {
              if (props.action.tooltip === "ir") {
                return (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                  >
                    <LinkRouter to={props.data.url}>Ir</LinkRouter>
                  </IconButton>
                );
              } else {
                return <div></div>;
              }
            },
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 30, 50],
            actionsColumnIndex: -1,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "filas",
              labelRowsPerPage: "Filas por pagina",
              firstAriaLabel: "Primer Pagina",
              firstTooltip: "Primer Pagina",
              previousAriaLabel: "Anterior",
              previousTooltip: "Anterior",
              nextAriaLabel: "Siguiente",
              nextTooltip: "Siguiente",
              lastAriaLabel: "Ultima Pagina",
              lastTooltip: "Ultima Pagina",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionada(s)",
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              addTooltip: "Agregar",
              deleteTooltip: "Borrar",
              editTooltip: "Editar",
              filterRow: {
                filterTooltip: "Filtros",
              },
              editRow: {
                deleteText: "Estas seguro de borrar este registro?",
                saveTooltip: "Guardar",
                cancelTooltip: "Cancelar",
              },
            },
          }}
        />

        {/* 
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Publicacion</TableCell>
              <TableCell>Ir</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((l) => (
              <TableRow key={l.updatedAt + l.publicacion.idItem}>
                <TableCell>{l.updatedAt}</TableCell>
                <TableCell>{l.usuario.username}</TableCell>
                <TableCell>{l.usuario.email}</TableCell>
                <TableCell>{l.publicacion.title}</TableCell>
                <TableCell>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                  >
                    <LinkRouter to={`/publicaciones/${l.publicacion.idItem}`}>
                      Ir
                    </LinkRouter>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
         */}
      </Paper>
    </Fragment>
  );
};
