import React, { Fragment, useContext, useState } from "react";
import { Context } from "./../Context";
import { UserForm } from "./../components/UserForm";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN } from "../graphql/publicaciones.mutation";

export const Login = () => {
  const [errorMsg, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { activateAuth } = useContext(Context);

  const [login] = useMutation(LOGIN, {
    onCompleted: data => {
      setLoading(false);
    }
  });

  const onSubmit = ({ username, password }) => {
    const input = { username, password };
    const variables = { input };
    setLoading(true);
    login({ variables })
      .then(({ data }) => {
        const { login } = data;
        if (login) activateAuth(login);
      })
      .catch(error => {
        setError("Usuario o contraseña incorrectos.");
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <UserForm
        disabled={loading}
        onSubmit={onSubmit}
        error={errorMsg}
        title="Iniciar sesion"
      />
    </Fragment>
  );
};
