import gql from "graphql-tag";

export const LOGS = gql`
  query logs($limit: Int!, $offset: Int!) {
    logs(limit: $limit, offset: $offset) {
      usuario {
        username
        email
      }
      updatedAt(format: "dd/mm/yyyy HH:MM")
      publicacion {
        idItem
        title
      }
    }
  }
`;
