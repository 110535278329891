import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export const ModalGuardando = ({ open }) => {
  return (
    <div>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Guardando...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
