import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import AddCircle from "@material-ui/icons/AddCircle";
import { IconButton } from "@material-ui/core";

export default function TelefonoDialog({ aceptar, item }) {
  const [open, setOpen] = React.useState(false);
  const [telefono, setTelefono] = React.useState(item != null ? item : "");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmar = () => {
    if (telefono.length > 0) {
      aceptar(telefono);
      handleClose();
    }
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        {item != null ? <EditIcon /> : <AddCircle />}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Telefonos</DialogTitle>
        <DialogContent>
          <DialogContentText>Ingresar número de teléfono.</DialogContentText>
          <TextField
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            autoFocus
            margin="dense"
            id="tel"
            label="Telefono"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmar} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
