import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Link } from "./styles";
import { Link as LinkRouter } from "@reach/router";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block"
    // }
  },
  title: {
    // display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const Appbar = ({ removeAuth, localidad, setLocalidad }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const token = localStorage.getItem("authorization");

  const usuario = token !== null ? parseJwt(token) : null;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={removeAuth}>Salir</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {usuario &&
        (usuario.username === "admin" || usuario.username === "Valeria") && (
          <MenuItem>
            <Link to={`/loglab`}>Logs</Link>
          </MenuItem>
        )}
      <MenuItem>
        <Link to={`/top`}>Top Visitados</Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/publicaciones`}>Publicaciones</Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/gratis`}>Gratis</Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/farmacias`}>Farmacias</Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/banners`}>Banners</Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/usuarios`}>Usuarios</Link>
      </MenuItem>
      <MenuItem onClick={removeAuth}>Salir</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <LinkRouter to="/">
              <img src="/favicon.ico" alt="la guia local" height="35" />
            </LinkRouter>
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            La Guia Local
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {usuario &&
              (usuario.username === "admin" ||
                usuario.username === "Valeria") && (
                <MenuItem>
                  <Link to={`/loglab`}>Logs</Link>
                </MenuItem>
              )}
            <MenuItem>
              <Link to={`/top`}>Top Visitados</Link>
            </MenuItem>
            <MenuItem>
              <Link to={`/publicaciones`}>Publicaciones</Link>
            </MenuItem>
            <MenuItem>
              <Link to={`/gratis`}>Gratis</Link>
            </MenuItem>
            <MenuItem>
              <Link to={`/farmacias`}>Farmacias</Link>
            </MenuItem>
            <MenuItem>
              <Link to={`/banners`}>Banners</Link>
            </MenuItem>
            <MenuItem>
              <Link to={`/usuarios`}>Usuarios</Link>
            </MenuItem>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};
