import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit, AddAPhoto, NavigateNext } from "@material-ui/icons";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Grid from "@material-ui/core/Grid";
import {
  FormLabel,
  Input,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

import { navigate } from "@reach/router";

import clsx from "clsx";

import {
  PicturesContainer,
  Image,
  Banner,
  Photo,
  ContainerImage,
  ContainerBanner,
  ContainerOPtions,
  ContainerPhotos,
  Card,
  AddPhoto,
} from "./styles";
import { Ubicacion } from "../Ubicacion";
import { UploadOneFile } from "../fileUpload";

import { MultipleUpload } from "../fileUploadMultiple";

import { SpeedDialButton } from "../SpeedDialButton";
import {
  UPDATE_PUBLICACION,
  DELETE_PUBLICACION,
} from "../../graphql/publicaciones.mutation";
// import {
//   PUBLICACIONES_GRATIS,
//   PUBLICACIONES_PAGAS_QUERY,
//   PUBLICACIONES_VERMAS_QUERY
// } from "../../graphql/publicaciones.query";
import { useMutation } from "@apollo/react-hooks";
import { Alert } from "../Alert";

import ModalTelefono from "./../ModalTelefono";
import ModalHorario from "./../ModalHorario";
import ModalSucursal from "./../ModalSucursal";
import ModalRubro from "./../ModalRubro";
import ModalEtiqueta from "./../ModalEtiqueta";
import ModalConfirmar from "./../ModalConfirmar";

import codificar from "./../../codificar";

import RLDD from "react-list-drag-and-drop/lib/RLDD";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    padding: theme.spacing(3, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textFieldShort: {
    width: 150,
  },
  textFieldLarge: {
    width: "40%",
  },
  table: {
    maxWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
    maxWidth: 450,
  },
}));

export const Publicacion = ({ info }) => {
  const [data, setData] = useState(info);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeLocalidades = (event) => {
    setData({ ...data, localidades: event.target.value });
  };

  const handleChangeServicios = (event) => {
    setData({ ...data, servicios: event.target.value });
  };

  const [updatePublicacion] = useMutation(UPDATE_PUBLICACION, {
    onCompleted: (data) => {
      setLoading(false);
    },
  });
  const [deletePublicacion] = useMutation(DELETE_PUBLICACION, {
    onCompleted: (data) => {
      setLoading(false);
    },
  });

  const classes = useStyles();

  const setLocation = (latitud, longitud) => {
    setData({
      ...data,
      datos: {
        ...data.datos,
        latitud: Number(latitud),
        longitud: Number(longitud),
      },
    });
  };

  const setLogo = (logo) => {
    setData({
      ...data,
      logo: logo,
    });
    setLoad(false);
  };

  const setBanner = (banner) => {
    setData({
      ...data,
      banner: banner,
    });
    setLoad(false);
  };

  const setPhotos = (photo) => {
    setData({
      ...data,
      fotos: data.fotos.concat(photo),
    });
    setLoad(false);
  };

  const handleBox = (e) => {
    if (data.box.indexOf(e.target.value) > -1) {
      setData({
        ...data,
        box: data.box.filter((b) => b !== e.target.value),
      });
    } else {
      setData({
        ...data,
        box: data.box.concat(e.target.value),
      });
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  console.log(data);

  return (
    <Fragment>
      <Dialog
        open={eliminar}
        aria-labelledby="form-dialog-title"
        maxWidth={"md"}
      >
        <DialogTitle id="form-dialog-title">
          Confirma Eliminar esta publicacion?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>La eliminación será permanente!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEliminar(false)}>Cancelar</Button>
          <Button
            onClick={async () => {
              setLoading(true);
              await deletePublicacion({
                variables: {
                  idItem: data.idItem,
                },
              });
              setEliminar(false);
              setLoading(false);
              navigate("/");
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Container className={classes.root}>
        <Paper className={classes.root}>
          <div>
            <FormLabel component="legend">Tipo de publicacion</FormLabel>

            <Select
              labelId="demo-simple-select-label"
              className={clsx(classes.margin, classes.textFieldLarge)}
              id="tipo"
              defaultValue={data.tipo ? data.tipo : "publicacion"}
              onChange={(e) =>
                setData({
                  ...data,
                  tipo: e.target.value,
                })
              }
            >
              <MenuItem value={"publicacion"}>Paga</MenuItem>
              <MenuItem value={"gratis"}>Gratis</MenuItem>
            </Select>

            <TextField
              className={clsx(classes.margin, classes.textFieldLarge)}
              style={{ border: "1px #ddd solid" }}
              placeholder="Recordatorio"
              multiline
              rows={5}
              rowsMax={5}
              defaultValue={data.recordatorio ? data.recordatorio : ""}
              onChange={(e) =>
                setData({
                  ...data,
                  recordatorio: e.target.value,
                })
              }
            />
          </div>
          <br />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  setData({
                    ...data,
                    pendiente: !data.pendiente,
                  })
                }
                id="pendiente"
                defaultChecked={data.pendiente}
              />
            }
            label="Pendiente"
          />
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={data.suspendido}
                onChange={(e) =>
                  setData({
                    ...data,
                    suspendido: !data.suspendido,
                  })
                }
                id="suspendido"
              />
            }
            label="Suspendido"
          />
          <div>
            <TextField
              label="Cod. Cliente"
              id="codCliente"
              type="number"
              className={clsx(classes.margin, classes.textFieldShort)}
              defaultValue={data.cliente}
              onChange={(e) =>
                setData({
                  ...data,
                  cliente: Number(e.target.value),
                })
              }
            />

            <TextField
              label="Número de venta"
              id="ventainterno"
              type="number"
              className={clsx(classes.margin, classes.textFieldShort)}
              defaultValue={data.ventainterno}
              onChange={(e) =>
                setData({
                  ...data,
                  ventainterno: Number(e.target.value),
                })
              }
            />

            <TextField
              label="Edición"
              id="edicion"
              type="number"
              className={clsx(classes.margin, classes.textFieldShort)}
              defaultValue={data.edicion}
              onChange={(e) =>
                setData({
                  ...data,
                  edicion: Number(e.target.value),
                })
              }
            />
            <TextField
              label="Orden"
              id="orden"
              type="number"
              className={clsx(classes.margin, classes.textFieldShort)}
              defaultValue={data.orden}
              onChange={(e) =>
                setData({
                  ...data,
                  orden: Number(e.target.value),
                })
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={data.esCentimetro}
                  onChange={(e) =>
                    setData({
                      ...data,
                      esCentimetro: !data.esCentimetro,
                    })
                  }
                  id="centimetro"
                />
              }
              label="Es Centrimetro?"
            />
          </div>

          <TextField
            id="title"
            label="Titulo"
            style={{ margin: 8 }}
            placeholder="Titulo"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={data.title}
            onChange={(e) =>
              setData({
                ...data,
                title: e.target.value,
              })
            }
          />

          <div>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-mutiple-checkbox-label">
                Localidad
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="localidad"
                multiple
                onChange={handleChangeLocalidades}
                defaultValue={
                  data.localidades ? data.localidades : ["concordia"]
                }
                input={<Input />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem value={"chajari"}>
                  <Checkbox
                    checked={data.localidades.indexOf("chajari") > -1}
                  />
                  <ListItemText primary={"Chajarí"} />
                </MenuItem>
                <MenuItem value={"santaana"}>
                  <Checkbox
                    checked={data.localidades.indexOf("santaana") > -1}
                  />
                  <ListItemText primary={" --Santa Ana"} />
                </MenuItem>
                <MenuItem value={"Villa del Rosario"}>
                  <Checkbox
                    checked={data.localidades.indexOf("Villa del Rosario") > -1}
                  />
                  <ListItemText primary={" --Villa del Rosario"} />
                </MenuItem>
                <MenuItem value={"colon"}>
                  <Checkbox checked={data.localidades.indexOf("colon") > -1} />
                  <ListItemText primary={"Colón"} />
                </MenuItem>

                <MenuItem value={"concepciondeluruguay"}>
                  <Checkbox
                    checked={
                      data.localidades.indexOf("concepciondeluruguay") > -1
                    }
                  />
                  <ListItemText primary={"Concepción del Uruguay"} />
                </MenuItem>

                <MenuItem value={"concordia"}>
                  <Checkbox
                    checked={data.localidades.indexOf("concordia") > -1}
                  />
                  <ListItemText primary={"Concordia"} />
                </MenuItem>
                <MenuItem value={"villaadela"}>
                  <Checkbox
                    checked={data.localidades.indexOf("villaadela") > -1}
                  />
                  <ListItemText primary={" --Villa Adela"} />
                </MenuItem>
                <MenuItem value={"villazorraquin"}>
                  <Checkbox
                    checked={data.localidades.indexOf("villazorraquin") > -1}
                  />
                  <ListItemText primary={" --Villa Zorraquin"} />
                </MenuItem>

                <MenuItem value={"federacion"}>
                  <Checkbox
                    checked={data.localidades.indexOf("federacion") > -1}
                  />
                  <ListItemText primary={"Federación"} />
                </MenuItem>

                <MenuItem value={"gualeguaychu"}>
                  <Checkbox
                    checked={data.localidades.indexOf("gualeguaychu") > -1}
                  />
                  <ListItemText primary={"Gualeguaychú"} />
                </MenuItem>

                <MenuItem value={"sanjose"}>
                  <Checkbox
                    checked={data.localidades.indexOf("sanjose") > -1}
                  />
                  <ListItemText primary={"San José"} />
                </MenuItem>

                <MenuItem value={"parana"}>
                  <Checkbox checked={data.localidades.indexOf("parana") > -1} />
                  <ListItemText primary={"Paraná"} />
                </MenuItem>

                <MenuItem value={"ubajay"}>
                  <Checkbox checked={data.localidades.indexOf("ubajay") > -1} />
                  <ListItemText primary={"Ubajay"} />
                </MenuItem>

                <MenuItem value={"villaelisa"}>
                  <Checkbox
                    checked={data.localidades.indexOf("villaelisa") > -1}
                  />
                  <ListItemText primary={"Villa Elisa"} />
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Dirección"
              id="direccion"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.direccion}
              onChange={(e) => {
                setData({
                  ...data,
                  datos: { ...data.datos, direccion: e.target.value },
                });
              }}
            />
          </div>

          <div>
            <TextField
              label="Latitud"
              id="latitud"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.latitud}
              value={data.datos.latitud}
              onChange={(e) => {
                setData({
                  ...data,
                  datos: {
                    ...data.datos,
                    latitud: Number(e.target.value) || 0,
                  },
                });
              }}
            />

            <TextField
              label="Longitud"
              id="longitud"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.longitud}
              onChange={(e) => {
                setData({
                  ...data,
                  datos: {
                    ...data.datos,
                    longitud: Number(e.target.value) || 0,
                  },
                });
              }}
            />
          </div>

          <div>
            <TextField
              label="Email"
              id="email"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.email}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, email: e.target.value },
                })
              }
            />

            <TextField
              label="Whatsapp"
              id="whatsapp"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.whatsapp}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, whatsapp: e.target.value },
                })
              }
            />
          </div>
          <div>
            <TextField
              label="Web"
              id="web"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.web}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, web: e.target.value },
                })
              }
            />
            <a
              href={data.datos.web}
              style={{ marginRight: "10px", textDecoration: "none" }}
            >
              <IconButton>
                IR <NavigateNext />{" "}
              </IconButton>
            </a>

            <TextField
              label="Facebook"
              id="facebook"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.facebook}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, facebook: e.target.value },
                })
              }
            />
            <a href={data.datos.facebook} style={{ textDecoration: "none" }}>
              <IconButton>
                IR <NavigateNext />{" "}
              </IconButton>
            </a>
          </div>
          <div>
            <TextField
              label="Twitter"
              id="twitter"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.twitter}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, twitter: e.target.value },
                })
              }
            />

            <a
              href={data.datos.twitter}
              style={{ marginRight: "10px", textDecoration: "none" }}
            >
              <IconButton>
                IR <NavigateNext />{" "}
              </IconButton>
            </a>

            <TextField
              label="Instagram"
              id="instagram"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.datos.instagram}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, instagram: e.target.value },
                })
              }
            />
            <a href={data.datos.instagram} style={{ textDecoration: "none" }}>
              <IconButton>
                IR <NavigateNext />{" "}
              </IconButton>
            </a>
          </div>
          <br />
          <div>
            <FormLabel component="legend">Servicios</FormLabel>
            <br />
            <TextareaAutosize
              id="servicios"
              rowsMax={8}
              rows={3}
              aria-label="maximum height"
              placeholder="Servicios"
              defaultValue={data.datos.servicios}
              style={{ width: "100%", fontSize: "14px" }}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, servicios: e.target.value },
                })
              }
            />
          </div>
          <br />
          <div>
            <FormLabel component="legend">Otros</FormLabel>
            <br />
            <TextareaAutosize
              id="otros"
              rowsMax={8}
              rows={3}
              aria-label="maximum height"
              placeholder="Otros"
              defaultValue={data.datos.otros}
              style={{ width: "100%", fontSize: "14px" }}
              onChange={(e) =>
                setData({
                  ...data,
                  datos: { ...data.datos, otros: e.target.value },
                })
              }
            />
          </div>
          <br />

          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Telefonos</FormLabel>
                <br />
                <Paper>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Numero</TableCell>
                        <TableCell align="center">Editar</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                        <TableCell align="center">
                          <TableCell align="center">
                            <ModalTelefono
                              aceptar={(item) => {
                                let telefonos =
                                  data.datos && data.datos.telefonos
                                    ? [...data.datos.telefonos]
                                    : [];
                                telefonos.push(item);

                                setData({
                                  ...data,
                                  datos: {
                                    ...data.datos,
                                    telefonos: telefonos,
                                  },
                                });
                              }}
                            />
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.datos &&
                        data.datos.telefonos &&
                        data.datos.telefonos.map((t, i) => (
                          <TableRow key={t}>
                            <TableCell align="left">{t}</TableCell>
                            <TableCell align="center">
                              <ModalTelefono
                                item={t}
                                aceptar={(item) => {
                                  let telefonos = data.datos.telefonos.map(
                                    (suc, ind) => {
                                      if (i === ind) {
                                        return item;
                                      }
                                      return suc;
                                    }
                                  );

                                  setData({
                                    ...data,
                                    datos: {
                                      ...data.datos,
                                      telefonos: telefonos,
                                    },
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <ModalConfirmar
                                confirmar={() => {
                                  let telefonos = data.datos.telefonos.filter(
                                    (num) => num !== t
                                  );
                                  setData({
                                    ...data,
                                    datos: {
                                      ...data.datos,
                                      telefonos: telefonos,
                                    },
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Anexo</FormLabel>
                <br />
                <TextareaAutosize
                  id="anexo"
                  rowsMax={8}
                  rows={3}
                  aria-label="maximum height"
                  placeholder="Anexo"
                  defaultValue={data.anexo.join("\n")}
                  style={{ width: "100%", fontSize: "14px" }}
                  onChange={e =>
                    setData({
                      ...data,
                      anexo: e.target.value.trim().split("\n")
                    })
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Horarios</FormLabel>
                <br />
                <Paper>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Horario</TableCell>
                        <TableCell align="center">Editar</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                        <TableCell align="center">
                          <TableCell align="center">
                            <ModalHorario
                              aceptar={(item) => {
                                let horarios = [...data.datos.horarios];
                                horarios.push(item);

                                setData({
                                  ...data,
                                  datos: { ...data.datos, horarios: horarios },
                                });
                              }}
                            />
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.datos.horarios.map((t, i) => (
                        <TableRow key={t}>
                          <TableCell align="left">{t}</TableCell>
                          <TableCell align="center">
                            <ModalHorario
                              item={t}
                              aceptar={(item) => {
                                let horarios = data.datos.horarios.map(
                                  (suc, ind) => {
                                    if (i === ind) {
                                      return item;
                                    }
                                    return suc;
                                  }
                                );

                                setData({
                                  ...data,
                                  datos: { ...data.datos, horarios: horarios },
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <ModalConfirmar
                              confirmar={() => {
                                let horarios = data.datos.horarios.filter(
                                  (rubro) => rubro !== t
                                );
                                setData({
                                  ...data,
                                  datos: {
                                    ...data.datos,
                                    horarios: horarios,
                                  },
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormLabel component="legend">Sucursales</FormLabel>
                <br />
                <Paper>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Direccion</TableCell>
                        <TableCell align="left">Telefono</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Whatsapp</TableCell>
                        <TableCell align="left">Lat</TableCell>
                        <TableCell align="left">Lon</TableCell>
                        <TableCell align="center">Editar</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                        <TableCell align="center">
                          <ModalSucursal
                            aceptar={(item) => {
                              let sucursales = [...data.sucursales];
                              sucursales.push(item);

                              setData({
                                ...data,
                                sucursales: sucursales,
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.sucursales.map((s, i) => (
                        <TableRow key={s.direccion}>
                          <TableCell align="left">{s.direccion}</TableCell>
                          <TableCell align="left">{s.telefono}</TableCell>
                          <TableCell align="left">{s.email}</TableCell>
                          <TableCell align="left">{s.whatsapp}</TableCell>
                          <TableCell align="left">{s.latitud}</TableCell>
                          <TableCell align="left">{s.longitud}</TableCell>
                          <TableCell align="center">
                            <ModalSucursal
                              item={s}
                              aceptar={(item) => {
                                let sucursales = data.sucursales.map(
                                  (suc, ind) => {
                                    if (i === ind) {
                                      return item;
                                    }
                                    return suc;
                                  }
                                );

                                setData({
                                  ...data,
                                  sucursales: sucursales,
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <ModalConfirmar
                              confirmar={() => {
                                let sucursales = data.sucursales.filter(
                                  (suc) => suc.direccion !== s.direccion
                                );
                                setData({
                                  ...data,
                                  sucursales: sucursales,
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Rubros</FormLabel>
                <br />
                <Paper>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                        <TableCell align="center">
                          <TableCell align="center">
                            <ModalRubro
                              allSelected={data.rubros}
                              aceptar={(item) => {
                                let rubros = [...data.rubros];
                                rubros.push(item);

                                setData({
                                  ...data,
                                  rubros: rubros,
                                });
                              }}
                            />
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.rubros.map((t) => (
                        <TableRow key={t}>
                          <TableCell align="left">{t}</TableCell>
                          <TableCell align="center">
                            <ModalConfirmar
                              confirmar={() => {
                                let rubros = data.rubros.filter(
                                  (horario) => horario !== t
                                );
                                setData({
                                  ...data,
                                  rubros: rubros,
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </div>

          <br />

          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormLabel component="legend">Etiquetas</FormLabel>
                <br />
                <Paper>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                        <TableCell align="center">
                          <TableCell align="center">
                            <ModalEtiqueta
                              allSelected={data.tags}
                              aceptar={(item) => {
                                let tags = [...data.tags];
                                tags.push(item);

                                setData({
                                  ...data,
                                  tags: tags,
                                });
                              }}
                            />
                          </TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.tags.map((t) => (
                        <TableRow key={t}>
                          <TableCell align="left">{t}</TableCell>
                          <TableCell align="center">
                            <ModalConfirmar
                              confirmar={() => {
                                let tags = data.tags.filter((tag) => tag !== t);
                                setData({
                                  ...data,
                                  tags: tags,
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </div>

          <br />
          <div>
            <FormLabel component="legend">Representa un servicio?</FormLabel>
            <br />

            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  Servicios
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="localidad"
                  multiple
                  onChange={handleChangeServicios}
                  defaultValue={data.servicios ? data.servicios : []}
                  input={<Input />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Emergencia"}>
                    <Checkbox
                      checked={data.servicios.indexOf("Emergencia") > -1}
                    />
                    <ListItemText primary={"Servicio 24 hs"} />
                  </MenuItem>

                  <MenuItem value={"Gastronomia"}>
                    <Checkbox
                      checked={data.servicios.indexOf("Gastronomia") > -1}
                    />
                    <ListItemText primary={"Gastronomia"} />
                  </MenuItem>

                  <MenuItem value={"ServicioTecnico"}>
                    <Checkbox
                      checked={data.servicios.indexOf("ServicioTecnico") > -1}
                    />
                    <ListItemText primary={"Servicio Técnico"} />
                  </MenuItem>

                  <MenuItem value={"Delibery"}>
                    <Checkbox
                      checked={data.servicios.indexOf("Delibery") > -1}
                    />
                    <ListItemText primary={"Delivery"} />
                  </MenuItem>

                  <MenuItem value={"EventosTurismo"}>
                    <Checkbox
                      checked={data.servicios.indexOf("EventosTurismo") > -1}
                    />
                    <ListItemText primary={"Eventos y Turismo"} />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <br />
            <br />
            {data.servicios.indexOf("Emergencia") > -1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_automotor"
                    value="automotor"
                    onChange={handleBox}
                    checked={data.box.indexOf("automotor") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_automotor" style={{ fontSize: "20px" }}>
                    Automotor
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_hogar_e"
                    value="hogar_e"
                    onChange={handleBox}
                    checked={data.box.indexOf("hogar_e") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_hogar_e" style={{ fontSize: "20px" }}>
                    Hogar
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_salud"
                    value="salud"
                    onChange={handleBox}
                    checked={data.box.indexOf("salud") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_salud" style={{ fontSize: "20px" }}>
                    Salud
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_transporte"
                    value="transporte"
                    onChange={handleBox}
                    checked={data.box.indexOf("transporte") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_transporte" style={{ fontSize: "20px" }}>
                    Transporte
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_varios_e"
                    value="varios_e"
                    onChange={handleBox}
                    checked={data.box.indexOf("varios_e") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_varios_e" style={{ fontSize: "20px" }}>
                    Varios
                  </label>
                </div>
              </div>
            )}
            {data.servicios.indexOf("ServicioTecnico") > -1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_tecnologia"
                    value="tecnologia"
                    onChange={handleBox}
                    checked={data.box.indexOf("tecnologia") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_tecnologia" style={{ fontSize: "20px" }}>
                    Tecnología
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_hogar_st"
                    value="hogar_st"
                    onChange={handleBox}
                    checked={data.box.indexOf("hogar_st") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_hogar_st" style={{ fontSize: "20px" }}>
                    Hogar
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_varios_st"
                    value="varios_st"
                    onChange={handleBox}
                    checked={data.box.indexOf("varios_st") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_varios_st" style={{ fontSize: "20px" }}>
                    Varios
                  </label>
                </div>
              </div>
            )}
            {data.servicios.indexOf("Gastronomia") > -1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_restaurantes"
                    value="restaurantes"
                    onChange={handleBox}
                    checked={data.box.indexOf("restaurantes") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label
                    for="checkbox_restaurantes"
                    style={{ fontSize: "20px" }}
                  >
                    Restaurantes
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_cervecerias"
                    value="cervecerias"
                    onChange={handleBox}
                    checked={data.box.indexOf("cervecerias") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label
                    for="checkbox_cervecerias"
                    style={{ fontSize: "20px" }}
                  >
                    Cervecerias
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_cafehelad"
                    value="cafehelad"
                    onChange={handleBox}
                    checked={data.box.indexOf("cafehelad") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_cafehelad" style={{ fontSize: "20px" }}>
                    Cafeterias y Heladería
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_sandwicherias"
                    value="sandwicherias"
                    onChange={handleBox}
                    checked={data.box.indexOf("sandwicherias") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label
                    for="checkbox_sandwicherias"
                    style={{ fontSize: "20px" }}
                  >
                    Sandwicherias
                  </label>
                </div>
              </div>
            )}
            {data.servicios.indexOf("EventosTurismo") > -1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_actividades"
                    value="actividades"
                    onChange={handleBox}
                    checked={data.box.indexOf("actividades") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label
                    for="checkbox_actividades"
                    style={{ fontSize: "20px" }}
                  >
                    Actividades
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_hoteleria"
                    value="hoteleria"
                    onChange={handleBox}
                    checked={data.box.indexOf("hoteleria") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_hoteleria" style={{ fontSize: "20px" }}>
                    Hoteleria
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_agencias"
                    value="agencias"
                    onChange={handleBox}
                    checked={data.box.indexOf("agencias") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_agencias" style={{ fontSize: "20px" }}>
                    Agencias
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_varios_e"
                    value="varios_e"
                    onChange={handleBox}
                    checked={data.box.indexOf("varios_e") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_varios_e" style={{ fontSize: "20px" }}>
                    Varios
                  </label>
                </div>
              </div>
            )}

            {data.servicios.indexOf("Delibery") > -1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "15px",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_gastronomia"
                    value="gastronomia"
                    onChange={handleBox}
                    checked={data.box.indexOf("gastronomia") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label
                    for="checkbox_gastronomia"
                    style={{ fontSize: "20px" }}
                  >
                    Gastronomia
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox_otros"
                    value="otros"
                    onChange={handleBox}
                    checked={data.box.indexOf("otros") > -1}
                    style={{ fontSize: "20px" }}
                  />
                  <label for="checkbox_otros" style={{ fontSize: "20px" }}>
                    Otros
                  </label>
                </div>
              </div>
            )}
          </div>
          <br />
          <br />

          <FormLabel component="legend">Seleccionar ubicacion</FormLabel>
          <br />
          <Ubicacion
            lat={data.datos.latitud}
            lng={data.datos.longitud}
            setLocation={setLocation}
          />
        </Paper>

        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Logo y banner
          </Typography>
          <PicturesContainer>
            <ContainerImage>
              <ContainerOPtions>
                <Fragment>
                  <UploadOneFile
                    id={"logoInput"}
                    set={setLogo}
                    setLoad={setLoad}
                    cliente={data.title.split(" ").join("_")}
                  />
                  {!load && (
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        let input = document.getElementById("logoInput");
                        input.click();
                      }}
                    >
                      <Edit color="primary" />
                    </IconButton>
                  )}
                </Fragment>

                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setData({
                      ...data,
                      logo: null,
                    });
                  }}
                >
                  <Delete color="error" />
                </IconButton>
              </ContainerOPtions>

              <Image
                src={
                  data.logo ? codificar(data.logo) : "/img/logo-publicacion.gif"
                }
              />
            </ContainerImage>
            <ContainerBanner>
              <ContainerOPtions>
                <Fragment>
                  <UploadOneFile
                    id={"bannerInput"}
                    set={setBanner}
                    setLoad={setLoad}
                    cliente={data.title.split(" ").join("_")}
                  />
                  {!load && (
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        let input = document.getElementById("bannerInput");
                        input.click();
                      }}
                    >
                      <Edit color="primary" />
                    </IconButton>
                  )}
                </Fragment>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setData({
                      ...data,
                      banner: null,
                    });
                  }}
                >
                  <Delete color="error" />
                </IconButton>
              </ContainerOPtions>
              <Banner
                src={
                  data.banner
                    ? codificar(data.banner)
                    : "/img/logo-publicacion.gif"
                }
              />
            </ContainerBanner>
          </PicturesContainer>
        </Paper>

        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Galeria
          </Typography>

          <ContainerPhotos>
            <RLDD
              layout={"grid"}
              items={data.fotos.map((f, i) => {
                return { id: i, url: f };
              })}
              itemRenderer={(p) => {
                return (
                  <Card key={p}>
                    <ContainerOPtions>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          let fotos = [...data.fotos];
                          let ind = fotos.indexOf(p.url);

                          if (ind > -1) {
                            fotos.splice(ind, 1);
                          }

                          setData({
                            ...data,
                            fotos: fotos,
                          });
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </ContainerOPtions>
                    <Photo src={codificar(p.url)} />
                  </Card>
                );
              }}
              onChange={(e) => {
                let fotos = e.map((f) => f.url);
                setData({
                  ...data,
                  fotos: fotos,
                });
              }}
            />

            {/* {data.fotos.map((p) => (
              <Card key={p}>
                <ContainerOPtions>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      let fotos = [...data.fotos];
                      let ind = fotos.indexOf(p);

                      if (ind > -1) {
                        fotos.splice(ind, 1);
                      }

                      setData({
                        ...data,
                        fotos: fotos,
                      });
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </ContainerOPtions>
                <Photo src={codificar(p)} />
              </Card>
            ))} */}
            <IconButton aria-label="add" size="medium">
              <Fragment>
                <MultipleUpload
                  id={"addPhotoInput"}
                  set={setPhotos}
                  setLoad={setLoad}
                  cliente={data.title.split(" ").join("_")}
                />
                {!load && (
                  <AddPhoto
                    onClick={() => {
                      let input = document.getElementById("addPhotoInput");
                      input.click();
                    }}
                  >
                    <AddAPhoto aria-label="edit">
                      <Edit color="primary" />
                    </AddAPhoto>
                  </AddPhoto>
                )}
              </Fragment>
            </IconButton>
          </ContainerPhotos>
        </Paper>
      </Container>
      <SpeedDialButton
        borrar={() => setEliminar(true)}
        cancelar={() => window.history.back()}
        guardar={() => {
          if (data.datos) {
            let newData = {
              ...data,
              datos: {
                ...data.datos,
                ...{
                  latitud: data.datos.latitud.toString(),
                  longitud: data.datos.longitud.toString(),
                },
              },
            };
            delete newData.idItem;
            delete newData.visitas;
            delete newData.__typename;
            delete newData.datos.__typename;
            delete newData.sucursales.__typename;

            if (newData.sucursales && newData.sucursales.length > 0) {
              let suc = newData.sucursales.map((s) => {
                return {
                  direccion: s.direccion || "",
                  telefono: s.telefono || "",
                  latitud: s.latitud || "",
                  longitud: s.longitud || "",
                  email: s.email || "",
                  whatsapp: s.whatsapp || "",
                };
              });
              newData.sucursales = suc;
            }

            setLoading(true);
            setOpen(true);
            updatePublicacion({
              variables: {
                idItem: data.idItem,
                publicacion: newData,
              },
            });
            // .then(res => {})
            // .catch(error => {});
          }
        }}
      />

      <Alert open={open} setOpen={setOpen} loading={loading} />
    </Fragment>
  );
};
