import React, { useState } from "react";
import { BannerDialog } from "../BannerDialog";
import { Container, Imagen } from "./styles";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_BANNER, DELETE_BANNER } from "../../graphql/banners.mutation";
import { BANNERS } from "../../graphql/banners.query";

export const Banner = ({ banner }) => {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [updateBanner] = useMutation(UPDATE_BANNER, {
    onCompleted: data => {
      setSaving(false);
    },

    update(cache, { data: { updateBanner } }) {
      const { banners } = cache.readQuery({
        query: BANNERS,
        variables: {
          orientacion: banner.orientacion
        }
      });

      let newBanners = banners.map(b => {
        if (b.idItem === updateBanner.idItem) {
          return updateBanner;
        } else {
          return b;
        }
      });

      cache.writeQuery({
        query: BANNERS,
        variables: {
          orientacion: banner.orientacion
        },
        data: { banners: newBanners }
      });
    }
  });

  const [deleteBanner] = useMutation(DELETE_BANNER, {
    onCompleted: data => {
      setSaving(false);
    },

    update(cache, { data: { deleteBanner } }) {
      const { banners } = cache.readQuery({
        query: BANNERS,
        variables: {
          orientacion: banner.orientacion
        }
      });
      cache.writeQuery({
        query: BANNERS,
        variables: {
          orientacion: banner.orientacion
        },
        data: {
          banners: banners.filter(
            b => b !== undefined && b.idItem !== banner.idItem
          )
        }
      });
    }
  });

  return (
    <div>
      <BannerDialog
        open={open}
        saving={saving}
        banner={banner}
        title={"Editar banner"}
        cancelar={() => setOpen(false)}
        guardar={ban => {
          setSaving(true);
          let data = { ...ban };
          delete data.idItem;
          delete data.__typename;
          updateBanner({
            variables: {
              idItem: banner.idItem,
              banner: data
            }
          });
          setOpen(false);
        }}
        eliminar={async () => {
          setSaving(true);
          await deleteBanner({
            variables: {
              idItem: banner.idItem
            }
          });
          setOpen(false);
        }}
      >
        <Container onClick={() => setOpen(true)}>
          <Imagen
            src={banner.imagen}
            alt="banner"
            orientacion={banner.orientacion}
          />
        </Container>
      </BannerDialog>
    </div>
  );
};
