import gql from "graphql-tag";

export const BANNERS = gql`
  query banners($orientacion: String) {
    banners(orientacion: $orientacion) {
      idItem
      orientacion
      imagen
      linkeable
      url
    }
  }
`;
