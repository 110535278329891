import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "@material-ui/core";
import { ListaBanners } from "../components/ListaBanners";
import { BannerDialog } from "../components/BannerDialog";

import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { CREATE_BANNER } from "../graphql/banners.mutation";
import { BANNERS } from "../graphql/banners.query";
import { useMutation } from "@apollo/react-hooks";

const useStyles = makeStyles(theme => ({
  button: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    margin: theme.spacing(4),
    height: "60px",
    width: "60px",
    position: "fixed",
    borderRadius: "50%"
  }
}));

export const BannersPage = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const [createBanner] = useMutation(CREATE_BANNER, {
    onCompleted: data => {
      setSaving(false);
    },

    update(cache, { data: { createBanner } }) {
      const { banners } = cache.readQuery({
        query: BANNERS,
        variables: {
          orientacion: createBanner.orientacion
        }
      });
      cache.writeQuery({
        query: BANNERS,
        variables: {
          orientacion: createBanner.orientacion
        },
        data: {
          banners: banners
            .filter(b => b.idItem !== createBanner.idItem)
            .concat([createBanner])
        }
      });
    }
  });

  return (
    <Fragment>
      <Container style={{ marginBottom: "30px" }}>
        <Helmet>
          <title>La guia local - Banners</title>
          <meta name="description" content="Panel de banners" />
        </Helmet>

        <ListaBanners orientacion={"horizontal"} />

        <ListaBanners orientacion={"vertical"} />
      </Container>
      <BannerDialog
        open={open}
        saving={saving}
        title={"Nuevo banner"}
        cancelar={() => setOpen(false)}
        guardar={async banner => {
          if (banner.imagen && banner.imagen.length > 5) {
            setSaving(true);
            delete banner.idItem;

            await createBanner({ variables: { banner } });
            setOpen(false);
          }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setOpen(true)}
        >
          <Add />
        </Button>
      </BannerDialog>
    </Fragment>
  );
};
