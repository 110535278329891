import gql from "graphql-tag";

export const CREATE_FARMACIA = gql`
  mutation createFarmacia($farmacia: FarmaciaInput!) {
    createFarmacia(farmacia: $farmacia) {
      idItem
      nombre
      localidad
      direccion
      telefono
      lat
      lon
      dias
      fechas
    }
  }
`;

export const UPDATE_FARMACIA = gql`
  mutation updateFarmacia($idItem: Int!, $farmacia: FarmaciaInput!) {
    updateFarmacia(idItem: $idItem, farmacia: $farmacia) {
      idItem
      nombre
      localidad
      direccion
      telefono
      lat
      lon
      dias
      fechas
    }
  }
`;

export const DELETE_FARMACIA = gql`
  mutation deleteFarmacia($idItem: Int!) {
    deleteFarmacia(idItem: $idItem) {
      deletedCount
    }
  }
`;
