import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Container } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { FormLabel } from "@material-ui/core";
import { Helmet } from "react-helmet";

import clsx from "clsx";

import { Ubicacion } from "../components/Ubicacion";

import { SpeedDialButton } from "../components/SpeedDialButton";
import { useMutation } from "@apollo/react-hooks";
import { Alert } from "../components/Alert";
import { CREATE_FARMACIA } from "../graphql/farmacias.mutation";
import { FARMACIAS_TURNO } from "../graphql/farmacias.query";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    padding: theme.spacing(3, 2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textFieldShort: {
    width: 150,
  },
  textFieldLarge: {
    width: 550,
  },
}));

let modelo = {
  nombre: "",
  direccion: "",
  localidad: "concordia",
  telefono: "",
  dias: [],
  lat: -31.39589,
  lon: -58.0182326,
};

export const NuevoFarmaciaPage = () => {
  const [data, setData] = useState(modelo);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createFarmacia] = useMutation(CREATE_FARMACIA, {
    onCompleted: (data) => {
      setLoading(false);
    },
    update(cache, { data: { createFarmacia } }) {
      const { farmacias } = cache.readQuery({
        query: FARMACIAS_TURNO,
        variables: {
          localidad: data.localidad,
        },
      });
      cache.writeQuery({
        query: FARMACIAS_TURNO,
        variables: {
          localidad: data.localidad,
        },
        data: {
          farmacias: farmacias
            .filter((b) => b.idItem !== createFarmacia.idItem)
            .concat([createFarmacia]),
        },
      });
    },
  });

  const classes = useStyles();

  const setLocation = (latitud, longitud) => {
    setData({
      ...data,
      lat: Number(latitud),
      lon: Number(longitud),
    });
  };

  return (
    <Fragment>
      <Container className={classes.root}>
        <Helmet>
          <title>La guia local - Nueva Farmacia</title>
          <meta name="description" content="Panel de farmacias" />
        </Helmet>

        <Paper className={classes.root}>
          <TextField
            id="nombre"
            label="Nombre"
            style={{ margin: 8 }}
            placeholder="nombre"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={data.nombre}
            onChange={(e) =>
              setData({
                ...data,
                nombre: e.target.value,
              })
            }
          />

          <div>
            <FormControl>
              <InputLabel id="demo-simple-select-helper-label">
                Localidad
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className={clsx(classes.margin, classes.textFieldLarge)}
                id="localidad"
                defaultValue={data.localidad ? data.localidad : "concordia"}
                onChange={(e) =>
                  setData({
                    ...data,
                    localidad: e.target.value,
                  })
                }
              >
                <MenuItem value={"chajari"}>Chajarí</MenuItem>
                <MenuItem value={"colon"}>Colón</MenuItem>
                <MenuItem value={"concordia"}>Concordia</MenuItem>
                <MenuItem value={"federacion"}>Federación</MenuItem>
                <MenuItem value={"villaelisa"}>Villa Elisa</MenuItem>
                <MenuItem value={"sanjose"}>San José</MenuItem>
                <MenuItem value={"gualeguaychu"}>Gualeguaychú</MenuItem>
                <MenuItem value={"ubajay"}>Ubajay</MenuItem>
                <MenuItem value={"concepciondeluruguay"}>
                  Concepción del Uruguay
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Dirección"
              id="direccion"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.direccion}
              onChange={(e) => {
                setData({
                  ...data,
                  ...{
                    direccion: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div>
            <TextField
              label="Latitud"
              id="latitud"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.lat}
              value={data.lat}
              onChange={(e) => {
                setData({
                  ...data,
                  ...{
                    lat: Number(e.target.value) || 0,
                  },
                });
              }}
            />

            <TextField
              label="Longitud"
              id="longitud"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.lon}
              value={data.lon}
              onChange={(e) => {
                setData({
                  ...data,
                  ...{
                    lon: Number(e.target.value) || 0,
                  },
                });
              }}
            />
          </div>
          <div>
            <TextField
              label="Telefono"
              id="telefono"
              className={clsx(classes.margin, classes.textFieldLarge)}
              defaultValue={data.telefono}
              onChange={(e) =>
                setData({
                  ...data,
                  telefono: e.target.value,
                })
              }
            />
          </div>

          <br />

          <br />
          <FormLabel component="legend">Seleccionar ubicacion</FormLabel>
          <br />
          <Ubicacion lat={data.lat} lng={data.lon} setLocation={setLocation} />
        </Paper>
      </Container>
      <SpeedDialButton
        cancelar={() => window.history.back()}
        guardar={() => {
          if (data.nombre) {
            let newData = { ...data };

            setLoading(true);
            setOpen(true);
            createFarmacia({
              variables: {
                farmacia: newData,
              },
            });
            // .then(res => {})
            // .catch(error => {});
          }
        }}
      />

      <Alert open={open} setOpen={setOpen} loading={loading} />
    </Fragment>
  );
};
