import styled from "styled-components";

export const Imagen = styled.img`
  object-fit: cover;
  width: ${props => (props.orientacion === "horizontal" ? "400px" : "200px")};
  height: ${props => (props.orientacion === "horizontal" ? "200px" : "320px")};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
