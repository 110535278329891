import gql from "graphql-tag";

export const CREATE_PUBLICACION = gql`
  mutation createPublicacion($publicacion: PublicacionInput!) {
    createPublicacion(publicacion: $publicacion) {
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      visitas
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const UPDATE_PUBLICACION = gql`
  mutation updatePublicacion($idItem: Int!, $publicacion: PublicacionInput!) {
    updatePublicacion(idItem: $idItem, publicacion: $publicacion) {
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      visitas
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const DELETE_PUBLICACION = gql`
  mutation deletePublicacion($idItem: Int!) {
    deletePublicacion(idItem: $idItem)
  }
`;

export const UPLOAD_FILE = gql`
  mutation singleUpload($file: Upload!, $cliente: String!) {
    singleUpload(file: $file, cliente: $cliente) {
      url
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: UserCredentials!) {
    login(input: $input)
  }
`;

export const UPDATE_RATING = gql`
  mutation updateRating($idItem: Int!, $rating: Float!) {
    updateRating(idItem: $idItem, rating: $rating) {
      idItem
      box
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      title
      logo
      rubros
      fotos
      tags
      suspendido
      pendiente
      service
      tipo
      anexo
      localidades
      servicios
      visitas
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        codCliente
        localidad
        telefonos
        horarios
        direccion
      }
    }
  }
`;
