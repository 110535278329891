import React, { createContext, useState } from "react";
export const Context = createContext();

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return window.localStorage.getItem("authorization");
  });

  const value = {
    isAuth,
    activateAuth: (token) => {
      setIsAuth(true);
      window.localStorage.setItem("authorization", token);
    },
    removeAuth: () => {
      setIsAuth(false);
      window.localStorage.removeItem("authorization");
      window.location.href = "/";
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
