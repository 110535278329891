import gql from "graphql-tag";

export const FARMACIA = gql`
  query farmacia($idItem: Int!) {
    farmacia(idItem: $idItem) {
      idItem
      nombre
      lat
      lon
      localidad
      direccion
      telefono
      dias
      fechas
    }
  }
`;

export const FARMACIAS_TURNO = gql`
  query farmacias($localidad: String!) {
    farmacias(localidad: $localidad) {
      idItem
      nombre
      lat
      lon
      localidad
      direccion
      telefono
      dias
      fechas
    }
  }
`;
