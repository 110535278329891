import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  PUBLICACIONES_PAGAS_QUERY,
  ULTIMOS_MODIFICADOS,
} from "../graphql/publicaciones.query";
import { useQuery } from "@apollo/react-hooks";
import { Search } from "../components/search";
import { Container } from "@material-ui/core";
import {
  ListaPublicaciones,
  UltimosModificadosList,
} from "../components/listaPublicaciones";
import { AddButton } from "../components/AddButton";

const POSTS_PER_PAGE = 100;

const SeccionBusqueda = ({ localidad, value }) => {
  const { data, loading } = useQuery(PUBLICACIONES_PAGAS_QUERY, {
    variables: {
      limit: POSTS_PER_PAGE,
      offset: 0,
      localidad: localidad,
      queryString: value,
      todos: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Fragment>
      {data && data.publicaciones && (
        <ListaPublicaciones
          loading={loading}
          principales={data.publicaciones.filter((p) => !p.borrado)}
          value={value}
          localidad={localidad}
        />
      )}
    </Fragment>
  );
};

export const PublicacionesPage = () => {
  const [localidad, setLocalidad] = useState("concordia");
  const [value, setValue] = useState("");

  const { data, loading } = useQuery(ULTIMOS_MODIFICADOS, {
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>La guia local - Publicaciones</title>
          <meta name="description" content="Panel de publicaciones" />
        </Helmet>

        <Search
          localidad={localidad}
          setLocalidad={setLocalidad}
          value={value}
          setValue={setValue}
        />

        {value.length > 3 && (
          <SeccionBusqueda localidad={localidad} value={value} />
        )}

        {value.length < 4 && data && data.ultimosModificados && (
          <UltimosModificadosList
            loading={loading}
            principales={data.ultimosModificados.filter((p) => !p.borrado)}
          />
        )}
      </Container>
      <AddButton to={"/nuevo/publicacion"} />
    </Fragment>
  );
};
