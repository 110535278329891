import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { TOP_VISITADOS } from "../graphql/publicaciones.query";
import { useQuery } from "@apollo/react-hooks";
import { Container } from "@material-ui/core";
import { TopVisitadosList } from "../components/listaPublicaciones";
import { LocalidadSelect } from "../components/localidadSelect";

import AppBar from "@material-ui/core/AppBar";

export const Top = () => {
  const [localidad, setLocalidad] = useState("concordia");

  const { data, loading } = useQuery(TOP_VISITADOS, {
    variables: {
      localidad: localidad,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>TOP VISITADOS</title>
          <meta name="description" content="Panel de publicaciones" />
        </Helmet>

        <AppBar
          position="static"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: "40px",
            borderRadius: "50px",
            width: "100%",
          }}
        >
          <LocalidadSelect localidad={localidad} setLocalidad={setLocalidad} />
        </AppBar>

        {data && data.topVisitados && (
          <TopVisitadosList
            loading={loading}
            principales={data.topVisitados.filter((p) => !p.borrado)}
          />
        )}
      </Container>
    </Fragment>
  );
};
