import React from "react";
import { useInputValue } from "./../../hooks/useInputValue";
import { Form, Input, Title, Error, ContainerLogin } from "./styles";
import { SubmitButton } from "./../SubmitButton";

export const UserForm = ({ onSubmit, title, error, disabled }) => {
  const username = useInputValue("");
  const password = useInputValue("");

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ username: username.value, password: password.value });
  };

  return (
    <ContainerLogin>
      <Form disabled={disabled} onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <Input disabled={disabled} placeholder="Usuario" {...username} />
        <Input
          disabled={disabled}
          type="password"
          placeholder="Password"
          {...password}
        />
        <SubmitButton disabled={disabled}>{title}</SubmitButton>
        {error && <Error>{error}</Error>}
      </Form>
    </ContainerLogin>
  );
};
