import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircle from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";

export default function SucursalDialog({ aceptar, item }) {
  const [open, setOpen] = React.useState(false);

  const [email, setEmail] = React.useState(item != null ? item.email : "");

  const [whatsapp, setWhatsapp] = React.useState(
    item != null ? item.whatsapp : ""
  );

  const [telefono, setTelefono] = React.useState(
    item != null ? item.telefono : ""
  );

  const [direccion, setDireccion] = React.useState(
    item != null ? item.direccion : ""
  );

  const [latitud, setLatitud] = React.useState(
    item != null ? item.latitud : ""
  );

  const [longitud, setLongitud] = React.useState(
    item != null ? item.longitud : ""
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmar = () => {
    if (direccion.length > 0) {
      aceptar({
        telefono,
        direccion,
        latitud,
        longitud,
        email,
        whatsapp,
      });
      handleClose();
    }
  };

  return (
    <div>
      <IconButton
        onClick={handleClickOpen}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        {item != null ? <EditIcon /> : <AddCircle />}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Sucursales</DialogTitle>
        <DialogContent>
          <DialogContentText>Ingresar dirección y teléfono.</DialogContentText>
          <TextField
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
            autoFocus
            margin="dense"
            id="dir"
            label="Dirección"
            type="text"
            fullWidth
          />
          <TextField
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            margin="dense"
            id="tel"
            label="Teléfono"
            type="text"
            fullWidth
          />

          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="dense"
            id="tel"
            label="Email"
            type="text"
            fullWidth
          />

          <TextField
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            margin="dense"
            id="tel"
            label="Whatsapp"
            type="text"
            fullWidth
          />

          <TextField
            value={latitud}
            onChange={(e) => setLatitud(e.target.value)}
            margin="dense"
            id="lat"
            label="Latitud"
            type="text"
            fullWidth
          />
          <TextField
            value={longitud}
            onChange={(e) => setLongitud(e.target.value)}
            margin="dense"
            id="tel"
            label="Longitud"
            type="lon"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmar} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
