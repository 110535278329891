import React from "react";
import LocationPicker from "react-location-picker";

export const Ubicacion = ({
  lat = -31.39589,
  lng = -58.0182326,
  setLocation
}) => {
  const handleLocationChange = ({ position, address, places }) => {
    if (position.lat !== lat && position.lng !== lng) {
      setLocation(position.lat, position.lng);
    }
  };

  return (
    <div>
      <div>
        <LocationPicker
          containerElement={<div style={{ height: "100%" }} />}
          mapElement={<div style={{ height: "400px" }} />}
          defaultPosition={{ lat: Number(lat), lng: Number(lng) }}
          onChange={handleLocationChange}
          zoom={16}
          radius={20}
        />
      </div>
    </div>
  );
};
