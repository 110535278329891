import styled from "styled-components";

export const PicturesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 280px;
`;

export const ContainerImage = styled.div`
  width: 30%;
  max-width: 280px;
  align-self: auto;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  align-self: auto;
  border-radius: 50%;
  height: 100%;
`;

export const ContainerBanner = styled.div`
  width: 60%;
  position: relative;
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: auto;
`;

export const ContainerOPtions = styled.div`
  position: absolute;
  top: -25px;
  right: -10px;
`;

export const ContainerPhotos = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  min-height: 200px;
`;

export const Card = styled.div`
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
`;

export const AddPhoto = styled.div`
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  border: 1px solid black;
`;

export const Photo = styled.img`
  width: 180px;
  height: 180px;
  object-fit: cover;
  align-self: auto;
`;
