import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  option: {
    color: "white",
  },
}));

export const LocalidadSelect = ({ localidad, setLocalidad }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setLocalidad(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.option}
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={localidad}
          defaultValue="concordia"
          onChange={handleChange}
        >
          <MenuItem value={"chajari"}>Chajarí</MenuItem>
          <MenuItem value={"santaana"}> --Santa Ana</MenuItem>
          <MenuItem value={"villadelrosario"}> --Villa del Rosario</MenuItem>
          <MenuItem value={"colon"}>Colón</MenuItem>
          <MenuItem value={"concepciondeluruguay"}>
            Concepción del Uruguay
          </MenuItem>
          <MenuItem value={"concordia"}>Concordia</MenuItem>
          <MenuItem value={"villadela"}> --Villa Adela</MenuItem>
          <MenuItem value={"villazorraquin"}> --Villa Zorraquin</MenuItem>
          <MenuItem value={"federacion"}>Federación</MenuItem>
          <MenuItem value={"gualeguaychu"}>Gualeguaychú</MenuItem>
          <MenuItem value={"sanjose"}>San José</MenuItem>
          <MenuItem value={"parana"}>Paraná</MenuItem>
          <MenuItem value={"ubajay"}>Ubajay</MenuItem>
          <MenuItem value={"villaelisa"}>Villa Elisa</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
