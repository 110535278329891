import styled from "styled-components";
import { Link as LinkRouter } from "@reach/router";

export const Link = styled(LinkRouter)`
  text-decoration: none;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 1em;
`;

export const Card = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 6px;
  border: black 2px solid;
  border-radius: 14px;
  box-shadow: 1px 1px 1px 1px grey;
  text-align: center;
  height: 390px;
  &:hover {
    color: blue;
    box-shadow: 1px 1px 1px 1px magenta;
    background: grey;
    cursor: pointer;
  }
`;

export const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  align-self: auto;
  border-radius: 50%;
`;

export const Rubro = styled.span`
  color: black;
  font-size: 14px;
`;

export const Title = styled.span`
  color: black;
  font-size: 18px;
  font-weight: bold;
`;

export const Numero = styled.span`
  color: black;
  font-size: 16px;
`;

export const Disponible = styled.div`
  margin-top: 5px;
  border: black 1px solid;
  width: 80%;
  margin-left: 10%;
  background: green;
  color: white;
  font-weight: bold;
`;

export const Pendiente = styled.div`
  margin-top: 5px;
  border: black 1px solid;
  width: 80%;
  margin-left: 10%;
  background: orange;
  color: white;
  font-weight: bold;
`;

export const Suspendido = styled.div`
  margin-top: 5px;
  border: black 1px solid;
  width: 80%;
  margin-left: 10%;
  background: red;
  color: white;
  font-weight: bold;
`;
