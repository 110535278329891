import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { LocalidadSelect } from "../localidadSelect";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3),
    //   width: "auto"
    // }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("md")]: {
    //   width: 200
    // }
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "30px",
    borderRadius: "50px",
    width: "100%",
  },
  appbar: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "30px",
    borderRadius: "50px",
    width: "70%",
  },
  toolbar: {
    width: "100%",
    borderRadius: "50px",
  },
}));

let timeout;

export const Search = ({
  localidad = "concordia",
  setLocalidad,
  value,
  setValue,
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder="Buscar…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            // value={value}
            onChange={(e) => {
              clearTimeout(timeout);
              const val = e.target.value;
              timeout = setTimeout(() => {
                setValue(val);
                clearTimeout(timeout);
              }, 400);
            }}
          />

          <LocalidadSelect localidad={localidad} setLocalidad={setLocalidad} />
        </Toolbar>
      </AppBar>
    </Container>
  );
};
