import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { Farmacia } from "./../components/farmacia";
import { FARMACIA } from "../graphql/farmacias.query";

export const FarmaciaPage = ({ id }) => {
  const { data, loading, error } = useQuery(FARMACIA, {
    variables: {
      idItem: Number(id)
    }
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error || !data.farmacia) {
    return <span>Ocurrio un error...</span>;
  }

  return <Farmacia info={data.farmacia} />;
};
