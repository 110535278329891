import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NavigateNext from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  }
}));

export default function IconButtons({ data, enabled = true, agregar }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <span>{data.nombre}</span>
      {enabled && (
        <IconButton color="primary" aria-label="add to list">
          <NavigateNext onClick={() => agregar()} />
        </IconButton>
      )}
    </div>
  );
}
