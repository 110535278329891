import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const Alert = ({ open, setOpen, loading }) => {
  return (
    <div>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmación de almacenamiento"}
        </DialogTitle>
        {loading && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Guardando...
            </DialogContentText>
          </DialogContent>
        )}
        {!loading && (
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Los cambios se han almacenado satsifactoriamente
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                  // window.history.back();
                }}
                color="primary"
                autoFocus
              >
                Aceptar
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </div>
  );
};
