import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { Banner } from "../Banner";
import { ContainerList } from "./styles";
import { useQuery } from "@apollo/react-hooks";
import { BANNERS } from "../../graphql/banners.query";
export const ListaBanners = ({ orientacion }) => {
  const { data, loading } = useQuery(BANNERS, {
    variables: {
      orientacion: orientacion
    }
  });

  if (loading) {
    return <span>...</span>;
  }

  return (
    <Paper style={{ marginTop: "50px", minHeight: "300px", padding: "3px" }}>
      <Typography variant="h5" component="h3">
        Banners {orientacion}
      </Typography>

      <ContainerList orientacion={orientacion}>
        {data.banners.map(ban => (
          <Banner key={ban.idItem} banner={ban} />
        ))}
      </ContainerList>
    </Paper>
  );
};
