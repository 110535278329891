import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

import { Link } from "@reach/router";

const useStyles = makeStyles(theme => ({
  button: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    margin: theme.spacing(4),
    height: "60px",
    width: "60px",
    position: "fixed",
    borderRadius: "50%"
  }
}));

export const AddButton = ({ to = "/" }) => {
  const classes = useStyles();

  return (
    <Link to={to}>
      <Button variant="contained" color="primary" className={classes.button}>
        <Add />
      </Button>
    </Link>
  );
};
