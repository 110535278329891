import gql from "graphql-tag";

export const CREATE_ETIQUETA = gql`
  mutation createEtiqueta($etiqueta: EtiquetaInput!) {
    createEtiqueta(etiqueta: $etiqueta) {
      _id
      nombre
    }
  }
`;
