import styled from "styled-components";

export const ContainerList = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.orientacion === "horizontal"
      ? "repeat(auto-fit, minmax(400px, 1fr))"
      : "repeat(auto-fit, minmax(220px, 1fr))"};
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  min-height: 330px;
`;
