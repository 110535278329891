import React, { Fragment, useState } from "react";
import { Container, Paper, Typography } from "@material-ui/core";
import { USUARIOS } from "../graphql/usuarios.query";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { Helmet } from "react-helmet";

import { Add } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";
import { CREATE_USUARIO } from "../graphql/usuarios.mutation";

import { UsuarioDialog } from "../components/UsuarioDialog";
import { Usuario } from "../components/Usuario";

const useStyles = makeStyles((theme) => ({
  button: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    margin: theme.spacing(4),
    height: "60px",
    width: "60px",
    position: "fixed",
    borderRadius: "50%",
  },
}));

export const UsuariosPage = () => {
  const { data, loading } = useQuery(USUARIOS);
  const [open, setOpen] = useState(false);

  const [createUsuario] = useMutation(CREATE_USUARIO, {
    onCompleted: (data) => {},
    update(cache, { data: { createUsuario } }) {
      const { usuarios } = cache.readQuery({ query: USUARIOS });
      cache.writeQuery({
        query: USUARIOS,
        data: { usuarios: usuarios.concat([createUsuario]) },
      });
    },
  });

  const classes = useStyles();

  if (loading) {
    return <span>Loading...</span>;
  }

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>La guia local - Usuarios</title>
          <meta name="description" content="Panel de usuarios" />
        </Helmet>
        <Paper
          style={{
            marginTop: "50px",
            marginBottom: "30px",
            minHeight: "300px",
            padding: "4px",
          }}
        >
          <Typography variant="h5" component="h3">
            Lista de usuarios
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} padding={"default"}>
                  Email
                </TableCell>
                <TableCell align={"left"} padding={"default"}>
                  Username
                </TableCell>

                <TableCell align={"center"} padding={"default"}>
                  Editar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.usuarios &&
                data.usuarios
                  .filter((f) => f.username !== "admin")
                  .map((f) => <Usuario usuario={f} key={f.idItem} />)}
            </TableBody>
          </Table>
        </Paper>
      </Container>

      <UsuarioDialog
        open={open}
        title={"Nuevo usuario"}
        cancelar={() => setOpen(false)}
        guardar={async (usuario) => {
          delete usuario.idItem;
          delete usuario.repassword;
          usuario.rol = "admin";
          await createUsuario({ variables: { usuario } });
          setOpen(false);
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setOpen(true)}
        >
          <Add />
        </Button>
      </UsuarioDialog>
    </Fragment>
  );
};
