import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";

export default function NuevoDialog({ aceptar }) {
  const [open, setOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmar = () => {
    if (nombre.length > 0) {
      aceptar(nombre);
      handleClose();
    }
  };

  return (
    <div>
      <ListItem autoFocus button onClick={handleClickOpen}>
        <ListItemAvatar>
          <Avatar>
            <AddIcon color="primary" />
          </Avatar>
        </ListItemAvatar>

        <ListItemText primary="Nuevo Rubro" />
      </ListItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nuevo</DialogTitle>
        <DialogContent>
          <DialogContentText>Ingresar nombre.</DialogContentText>
          <TextField
            value={nombre}
            onChange={e => setNombre(e.target.value)}
            autoFocus
            margin="dense"
            id="tel"
            label="Nombre"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmar} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
