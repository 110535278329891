import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { UploadOneFile } from "../fileUpload";
import { Imagen, Container } from "./styles";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px",
    padding: theme.spacing(3, 2)
  },
  margin: {
    margin: theme.spacing(1)
  },
  textFieldShort: {
    width: 150
  },
  textFieldLarge: {
    width: 550
  }
}));

const modelo = {
  idItem: 0,
  orientacion: "horizontal",
  imagen: "",
  linkeable: false,
  url: ""
};

export const BannerDialog = ({
  banner = null,
  open = false,
  children,
  title = "",
  cancelar,
  guardar,
  eliminar,
  saving = false
}) => {
  const [data, setData] = useState(banner ? banner : modelo);
  const [load, setLoad] = useState(false);
  const classes = useStyles();

  return (
    <div>
      {children}
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth={"md"}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        {saving && (
          <DialogContent>
            <DialogContentText>
              Guardando cambios, espere por favor...
            </DialogContentText>
          </DialogContent>
        )}
        {!saving && (
          <Fragment>
            <DialogContent>
              <DialogContentText>
                Seleccione la orientacion, la imagen y si la misma debe adjuntar
                un link.
              </DialogContentText>
              <div>
                <FormControl>
                  <InputLabel id="demo-simple-select-helper-label">
                    Orientacion?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    className={clsx(classes.margin, classes.textFieldLarge)}
                    id="orientacion"
                    defaultValue={data.orientacion}
                    onChange={e =>
                      setData({
                        ...data,
                        orientacion: e.target.value
                      })
                    }
                  >
                    <MenuItem value={"horizontal"}>Horizontal</MenuItem>
                    <MenuItem value={"vertical"}>Vertical</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* Imagen */}
              <Container>
                <div>
                  <IconButton aria-label="add" size="medium">
                    <Fragment>
                      <UploadOneFile
                        id={"addPhotoInput"}
                        set={img => {
                          setData({
                            ...data,
                            imagen: img
                          });
                          setLoad(false);
                        }}
                        setLoad={setLoad}
                        cliente={"_"}
                      />
                      {!load && (
                        <div
                          onClick={() => {
                            let input = document.getElementById(
                              "addPhotoInput"
                            );
                            input.click();
                          }}
                        >
                          <Imagen
                            orientacion={data.orientacion}
                            src={
                              data.imagen && data.imagen.length > 4
                                ? data.imagen
                                : "/img/logo-publicacion.gif"
                            }
                            alt="banner"
                          />
                        </div>
                      )}
                    </Fragment>
                  </IconButton>
                </div>
              </Container>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e =>
                        setData({
                          ...data,
                          linkeable: !data.linkeable
                        })
                      }
                      id="pendiente"
                      defaultChecked={data.linkeable}
                    />
                  }
                  label="Linkeable?"
                />
                {data.linkeable && (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="url"
                    label="Url del link"
                    type="text"
                    fullWidth
                    defaultValue={data.url}
                    onChange={e =>
                      setData({
                        ...data,
                        url: e.target.value
                      })
                    }
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  cancelar();
                  if (!banner) {
                    setData(modelo);
                  } else {
                    setData(banner);
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  guardar(data);
                  if (!banner) {
                    setData(modelo);
                  }
                }}
              >
                Guardar
              </Button>

              {eliminar && (
                <Button
                  style={{ color: "red" }}
                  onClick={() => {
                    eliminar();
                  }}
                >
                  Eliminar
                </Button>
              )}
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </div>
  );
};
