import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { PUBLICACIONES_GRATIS } from "../graphql/publicaciones.query";
import { useQuery } from "@apollo/react-hooks";
import { Search } from "../components/search";
import { Container } from "@material-ui/core";
import { GratisList } from "../components/listaPublicaciones";

const POSTS_PER_PAGE = 30;

const SeccionBusqueda = ({ localidad, value }) => {
  const { data, loading } = useQuery(PUBLICACIONES_GRATIS, {
    variables: {
      limit: POSTS_PER_PAGE,
      offset: 0,
      localidad: localidad,
      queryString: value,
      todos: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <Fragment>
      {data && data.gratis && (
        <GratisList
          loading={loading}
          principales={data.gratis.filter((p) => !p.borrado)}
        />
      )}
    </Fragment>
  );
};

export const Gratis = () => {
  const [localidad, setLocalidad] = useState("concordia");
  const [value, setValue] = useState("");

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>La guia local - Publicaciones</title>
          <meta name="description" content="Panel de publicaciones" />
        </Helmet>

        <Search
          localidad={localidad}
          setLocalidad={setLocalidad}
          value={value}
          setValue={setValue}
        />

        <SeccionBusqueda localidad={localidad} value={value} />
      </Container>
    </Fragment>
  );
};
