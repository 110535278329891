import gql from "graphql-tag";

export const TOPTEN = gql`
  query topten($localidad: String!) {
    topten(localidad: $localidad) {
      visitas
      idItem
      title
      logo
    }
  }
`;

export const REALACIONADAS = gql`
  query relacionadas($idItem: Int!, $rubro: String!, $localidad: String) {
    publicacionesRelacionadas(
      idItem: $idItem
      rubro: $rubro
      localidad: $localidad
    ) {
      visitas
      idItem
      title
      logo
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        direccion
      }
    }
  }
`;

export const PUBLICACION = gql`
  query Publicacion($queryString: String!) {
    publicacion(queryString: $queryString) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const PUBLICACIONES_PAGAS_QUERY = gql`
  query Publicaciones(
    $limit: Int!
    $offset: Int!
    $localidad: String
    $queryString: String
    $todos: Boolean
  ) {
    publicaciones(
      limit: $limit
      offset: $offset
      localidad: $localidad
      queryString: $queryString
      todos: $todos
    ) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }

    countPost(localidad: $localidad, queryString: $queryString)
  }
`;

export const PUBLICACIONES_VERMAS_QUERY = gql`
  query VerMas(
    $limit: Int!
    $offset: Int!
    $localidad: String
    $queryString: String
    $todos: Boolean
  ) {
    verMas(
      limit: $limit
      offset: $offset
      localidad: $localidad
      queryString: $queryString
      todos: $todos
    ) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }

    countVerMas(localidad: $localidad, queryString: $queryString)
  }
`;

export const SERVICIOS = gql`
  query Servicios($localidad: String!, $servicio: String!, $box: [String]) {
    servicios(localidad: $localidad, servicio: $servicio, box: $box) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const SERVICIOS_DISPONIBLES = gql`
  query Servicios($localidad: String) {
    serviciosDisponibles(localidad: $localidad) {
      _id
    }
  }
`;

export const PUBLICACION_BY_ID = gql`
  query Publicacion($idItem: Int!, $todo: Boolean) {
    publicacionByID(idItem: $idItem, todo: $todo) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const PUBLICACIONES_GRATIS = gql`
  query Publicaciones(
    $limit: Int!
    $offset: Int!
    $localidad: String
    $queryString: String
    $todos: Boolean
  ) {
    gratis(
      limit: $limit
      offset: $offset
      localidad: $localidad
      queryString: $queryString
      todos: $todos
    ) {
      visitas
      title
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const TOP_VISITADOS = gql`
  query topVisitados($localidad: String!) {
    topVisitados(localidad: $localidad) {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;

export const ULTIMOS_MODIFICADOS = gql`
  query ultimosModificados {
    ultimosModificados {
      visitas
      title
      recordatorio
      box
      idItem
      ventainterno
      edicion
      cliente
      orden
      esCentimetro
      tags
      rubros
      banner
      logo
      fotos
      suspendido
      pendiente
      service
      tipo
      anexo
      servicios
      localidades
      sucursales {
        direccion
        telefono
        latitud
        longitud
        whatsapp
        email
      }
      datos {
        localidad
        direccion
        telefonos
        horarios
        twitter
        facebook
        instagram
        email
        servicios
        otros
        latitud
        longitud
        whatsapp
        web
        ratings
        votos
        codCliente
      }
    }
  }
`;
