import gql from "graphql-tag";

export const RUBROS = gql`
  query rubros($nombre: String!, $limit: Int!, $offset: Int!) {
    rubros(nombre: $nombre, offset: $offset, limit: $limit) {
      nombre
      _id
    }
  }
`;
