import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { SelectLocalidad } from "../components/SelectLocalidad";
import { Container, IconButton } from "@material-ui/core";
import { AddButton } from "../components/AddButton";
import { FARMACIAS_TURNO } from "../graphql/farmacias.query";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import {
  UPDATE_FARMACIA,
  DELETE_FARMACIA
} from "../graphql/farmacias.mutation";
import { ModalGuardando } from "../components/ModalGuardando";

import { Edit } from "@material-ui/icons";

import { Link } from "@reach/router";
import { AlertDelete } from "../components/AlertDelete";

import Calendario from "./../components/Calendario";

export const FarmaciasPage = ({ id }) => {
  const [localidad, setLocalidad] = useState("concordia");
  const [value, setValue] = useState("");
  const [saving, setSaving] = useState(false);

  const [updateFarmacia] = useMutation(UPDATE_FARMACIA, {
    onCompleted: data => {
      setSaving(false);
    }
  });

  const [deleteFarmacia] = useMutation(DELETE_FARMACIA, {
    variables: {
      idItem: id
    },
    onCompleted: data => {
      window.location.reload();
    }
  });

  const { data } = useQuery(FARMACIAS_TURNO, {
    variables: {
      localidad: localidad
    }
  });

  return (
    <Fragment>
      <Container>
        <Helmet>
          <title>La guia local - Farmacias</title>
          <meta name="description" content="Panel de farmacias" />
        </Helmet>

        <SelectLocalidad
          localidad={localidad}
          setLocalidad={setLocalidad}
          value={value}
          setValue={setValue}
        />

        <Paper style={{ marginTop: "50px", marginBottom: "30px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"left"} padding={"default"}>
                  Farmacia
                </TableCell>
                <TableCell align={"left"} padding={"default"}>
                  Direccion
                </TableCell>
                <TableCell align={"center"} padding={"default"}>
                  Fechas
                </TableCell>
                <TableCell align={"center"} padding={"default"}>
                  Editar
                </TableCell>
                <TableCell align={"center"} padding={"default"}>
                  Borrar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.farmacias &&
                data.farmacias.map(f => (
                  <TableRow key={f.idItem} hover role="checkbox" tabIndex={-1}>
                    <TableCell padding="checkbox">{f.nombre}</TableCell>
                    <TableCell padding="checkbox">{f.direccion}</TableCell>

                    <TableCell padding="checkbox" align="center">
                      <Calendario
                        nombre={f.nombre}
                        fechas={f.fechas || []}
                        update={fechas => {
                          let farmacia = { ...f };
                          delete farmacia.idItem;
                          delete farmacia.__typename;

                          farmacia.fechas = fechas;
                          setSaving(true);
                          updateFarmacia({
                            variables: {
                              idItem: f.idItem,
                              farmacia: farmacia
                            }
                          });
                        }}
                      />
                    </TableCell>

                    <TableCell padding="checkbox" align="center">
                      <Link to={`/farmacias/${f.idItem}`}>
                        <IconButton aria-label="edit">
                          <Edit />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell padding="checkbox" align="center">
                      <AlertDelete
                        id={f.idItem}
                        action={id => {
                          deleteFarmacia({
                            variables: {
                              idItem: id
                            }
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Container>
      <AddButton to={"/nuevo/farmacia"} />

      <ModalGuardando open={saving} />
    </Fragment>
  );
};
