import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPLOAD_FILE } from "../../graphql/publicaciones.mutation";

import Resizer from "react-image-file-resizer";

export const MultipleUpload = ({ id, set, setLoad, cliente }) => {
  const [uploading, setUploading] = useState(false);

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: (e) => {
      set(e.singleUpload.url);
    },
  });

  const uploadMultipleFiles = async (e) => {
    let fil = Object.keys(e.target.files).map((f) => e.target.files[f]);

    try {
      fil.map((f, i) => {
        return Resizer.imageFileResizer(
          fil[i],
          800,
          600,
          "JPEG",
          100,
          0,
          async (uri) => {
            setUploading(true);
            await uploadFile({ variables: { file: uri, cliente } });
            setUploading(false);
          },
          "blob"
        );
      });
    } catch (err) {
      console.log({ err });
    }
  };

  if (uploading) {
    return <span>Loading...</span>;
  }

  return (
    <input
      id={id}
      type="file"
      accept="image/*"
      multiple
      required
      style={{ display: "none" }}
      onChange={uploadMultipleFiles}

      //   {
      //   target: {
      //     validity,
      //     files: [file],
      //   },
      // }) => {
      //   setUploading(true);
      //   setLoad(true);
      //   return validity.valid && uploadFile({ variables: { file, cliente } });
      // }
    />
  );
};
